/* =| スタジアムマップページ本体 |= */
import { useEffect, useState } from 'react';
import { getStadiumMapData } from '../../domain/usecase/usecaseStadiumMap'
import { AreaData, StadiumMapData } from '../../domain/models/stadiumMapData'
import {
  fetchStrageSelectedEvent, fetchStrageMapType,
  fetchStrageFloorNo, fetchStrageSelectedCategory, storeSelectData
} from '../hooks/context';

// 
import { MapView } from './mapView';
import { InformationView } from './informationView';
// 
import './styles/studiumMap.css';
// ==

export function StudiumMap() {
  //エラー発生状態か
  const [isError, setIsError] = useState<boolean>(false);
  // マップ表示初期化フラグ
  const [mapRefresh, setMapRefresh] = useState<boolean>(false);

  // スタジアムマップJsonから取れるデータ
  const [mapData, setMapData] = useState<StadiumMapData>({ peripheryMapInfos: [], insideMapInfos: [], categoryInfos: [] });
  // 選択された施設のID (eventId)
  const [selectedEventId, setSelectedEventId] = useState<string>(fetchStrageSelectedEvent());
  // 表示中のスタジアムマップの範囲 [0:周辺, 1:内部] ※現状周辺と内部のみなのでこの二つ
  const [targetMap, setTargetMap] = useState<number>(fetchStrageMapType());//初期値はスタジアム周辺
  // フィルタリングをするカテゴリのID [絞り込むcategoryId (空文字は全件表示)]
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>(fetchStrageSelectedCategory());
  // 階層
  const [floor, setFloor] = useState<number>(fetchStrageFloorNo()); //フロア
  // 表示中のマップのエリアデータ
  const [selectedAreaData, setSelectedAreaData] = useState<Array<AreaData>>([]);
  // 詳細情報の選択部分に自動スクロールさせるか
  const [needScroll, setNeedScroll] = useState<boolean>(false);
  // 
  useEffect(() => {
    // ダイアログ初期化
    setIsError(false);
    //
    getStadiumMapData().then((models) => {
      if (models) setMapData(models);
      else setIsError(true);
    });
  }, []);

  // 変更の保管
  useEffect(() => {
    storeSelectData(selectedEventId, targetMap, floor, selectedCategoryId);
  }, [selectedEventId, targetMap, floor, selectedCategoryId]);

  // 詳細表示データの成形 
  useEffect(() => {
    var map = null
    switch (targetMap) {
      case 0: //周辺マップ
        map = mapData.peripheryMapInfos[0]
        break;
      case 1: //屋内マップ
        map = mapData.insideMapInfos[floor]
        break;
      default: //異常値なら何もない表示
        break;
    }
    if (map) {
      var areaData: Array<AreaData> = map.areaInfos
      // カテゴリ選択中ならフィルタリング
      if (selectedCategoryId !== '') {
        areaData = areaData.filter(area => {
          return area.facilitys.some(it => it.markerCategoryId === selectedCategoryId);
        })
        const filteredArea: Array<AreaData> = areaData.map(area => ({
          title: area.title,
          facilitys: area.facilitys.filter(facilitye => facilitye.markerCategoryId === selectedCategoryId)
        }));
        setSelectedAreaData(filteredArea)
      } else {
        setSelectedAreaData(areaData)
      }
    } else {
      setSelectedAreaData([])
    }
    // スクロール
    var infoAreaBody = document.getElementById('facility-info');//詳細領域
    infoAreaBody?.scrollTo(0, 0);
  }, [mapData, targetMap, selectedCategoryId, floor]);

  return (
    <div id='stadium-map' className='body'>
      <ErrorDialog isShow={isError} />
      <MapView mapData={mapData} selectedEventId={selectedEventId} setSelectedEventId={setSelectedEventId}
        setSelectedAreaData={setSelectedAreaData} targetMap={targetMap} setTargetMap={setTargetMap}
        selectedCategoryId={selectedCategoryId} setSelectedCategoryId={setSelectedCategoryId} floor={floor}
        setFloor={setFloor} mapRefresh={mapRefresh} setMapRefresh={setMapRefresh} needScroll={needScroll} setNeedScroll={setNeedScroll} />
      <InformationView areaData={selectedAreaData} selectedEventId={selectedEventId}
        setSelectedEventId={setSelectedEventId} setMapRefresh={setMapRefresh} setNeedScroll={setNeedScroll} />
    </div>
  );
}

const ErrorDialog = (DialogProp: { isShow: boolean }) => {
  return DialogProp.isShow ?
    (
      <>
        <div id='dialog' className='bg-cover' />
        <div id='dialog' className='server-error'>
          <div id='dialog' className='body' >
            <h4>スタジアムマップ情報の取得に失敗しました。</h4>
          </div>
        </div>
      </>
    ) : (<></>);
}
