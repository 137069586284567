/*=| 地図表示部分 |=*/
import React, { useEffect, useRef, useState } from 'react';
import { TransformWrapper, TransformComponent, ReactZoomPanPinchContentRef, ReactZoomPanPinchHandlers } from "react-zoom-pan-pinch";

import { StadiumMapData, MapData, FacilityData, AreaData } from '../../domain/models/stadiumMapData'
import { getMapTypeName, mapMarkerSetting, textSettings, contentWidth } from '../value/values';

//
import './styles/mapView.css';

// マーカー関係のサイズ
const markerHalf = mapMarkerSetting.size / 2; //アイコンの半分(座標をずらすための物)
const markerBorder = mapMarkerSetting.borderWidth; //ボーダーサイズ(両脇にあるので半分の2倍)

// ==
export function MapView(StadiumMapProps: {
  mapData: StadiumMapData, selectedEventId: string, setSelectedEventId: any, setSelectedAreaData: any, targetMap: number,
  setTargetMap: any, selectedCategoryId: string, setSelectedCategoryId: any, floor: number, setFloor: any, mapRefresh: boolean,
  setMapRefresh: any, needScroll: boolean, setNeedScroll: any
}) {
  return (
    <div id="map" className="body">
      <MapSelectTab targetMap={StadiumMapProps.targetMap} setTargetMap={StadiumMapProps.setTargetMap}
        setSelectedEventId={StadiumMapProps.setSelectedEventId} setSelectedCategoryId={StadiumMapProps.setSelectedCategoryId}
        setMapRefresh={StadiumMapProps.setMapRefresh} />
      <CategoryTab data={StadiumMapProps.mapData} targetMap={StadiumMapProps.targetMap}
        selectedCategoryId={StadiumMapProps.selectedCategoryId} selectedEventId={StadiumMapProps.selectedEventId}
        setSelectedCategoryId={StadiumMapProps.setSelectedCategoryId} setSelectedEventId={StadiumMapProps.setSelectedEventId}
        floor={StadiumMapProps.floor} />
      <StadiumMap stadiumMapData={StadiumMapProps.mapData} typeNo={StadiumMapProps.targetMap}
        selectedCategoryId={StadiumMapProps.selectedCategoryId} selectedEventId={StadiumMapProps.selectedEventId}
        setSelectedEventId={StadiumMapProps.setSelectedEventId} setSelectedAreaData={StadiumMapProps.setSelectedAreaData}
        floor={StadiumMapProps.floor} setFloor={StadiumMapProps.setFloor} mapRefresh={StadiumMapProps.mapRefresh}
        setMapRefresh={StadiumMapProps.setMapRefresh} setSelectedCategoryId={StadiumMapProps.setSelectedCategoryId}
        needScroll={StadiumMapProps.needScroll} setNeedScroll={StadiumMapProps.setNeedScroll} />
    </div>
  );
}

// = マップ種類選択タブ
function MapSelectTab(SelecterProp: { targetMap: number, setTargetMap: any, setSelectedEventId: any, setSelectedCategoryId: any, setMapRefresh: any }) {
  function mapChange(typeNo: number) {
    //施設の選択状況の破棄とマップ切替
    SelecterProp.setSelectedEventId('');
    SelecterProp.setTargetMap(typeNo);
    //カテゴリ選択のリセット
    SelecterProp.setSelectedCategoryId('')
    SelecterProp.setMapRefresh(true)
  }
  // タブの表示切替
  changeMapSelectTabState(SelecterProp.targetMap);
  //
  return (
    <div id='map-tab' className='body'>
      <input type='button' id='map-tab' className='cell' onClick={() => mapChange(0)} value={getMapTypeName(0)} />
      <input type='button' id='map-tab' className='cell' onClick={() => mapChange(1)} value={getMapTypeName(1)} />
    </div>
  );
}


// タブの切替
function changeMapSelectTabState(typeNo: number) {
  var tabs = document.getElementById('map-tab')?.getElementsByClassName('cell');
  if (tabs) {
    var tabAray = Array.from(tabs);
    tabAray.forEach((tab, idx) => {
      if (idx === typeNo) tab.setAttribute("disabled", 'true');
      else tab.removeAttribute("disabled");
    });
  }
}

// = カテゴリ選択タブ
function CategoryTab(CategoryProp: {
  data: StadiumMapData, targetMap: number, selectedCategoryId: string, selectedEventId: string,
  setSelectedCategoryId: any, setSelectedEventId: any, floor: number
}) {
  const categorys = CategoryProp.data.categoryInfos; //カテゴリ一覧
  const currentId = CategoryProp.selectedCategoryId; //選択中のカテゴリID
  const eventId = CategoryProp.selectedEventId; //選択中のイベントID
  const targetMap = CategoryProp.targetMap; //選択中のマップタイプ(周辺/内部)
  // 
  var categoryTabs = categorys.map(category => {
    function categorySelect(selectId: string) {
      var idText = (currentId === selectId) ? '' : selectId;
      var mapData: Array<MapData>;
      switch (targetMap) {
        case 0: mapData = CategoryProp.data.peripheryMapInfos; break;
        case 1: mapData = CategoryProp.data.insideMapInfos; break;
        default: mapData = [];
      }
      // 選択中の物がカテゴリ内に無ければ外す
      if (!findSelectedItem(mapData, eventId, selectId)) { CategoryProp.setSelectedEventId(''); }
      CategoryProp.setSelectedCategoryId(idText);
    }
    // 
    var name = (category.name.length <= textSettings.maxCategoryName)
      ? category.name
      : category.name.slice(0, textSettings.maxCategoryName) + '…';
    var color = category.markerColor;
    var icon = (category.markerURL?.length > 0) ?
      <img className='category-mark' src={category.markerURL} />
      : <div className='category-mark' style={{ borderRadius: '50%', backgroundColor: color }} />;

    // タブ選択状態
    var tabStyle = (currentId === category.categoryId) ?
      { backgroundColor: '#e2a6e8' } : { backgroundColor: '#FFFFFF' };

    return isCategoryDataEmpty(CategoryProp.data, targetMap, CategoryProp.floor, category.categoryId) ? (<></>) : (
      <div id='category-tab' onClick={() => { categorySelect(category.categoryId) }} className='tab' style={tabStyle}>
        {icon}
        <div className='category-name'>{name}</div>
      </div>
    );
  });
  return (
    <div id='category-tab' className='body'>
      {categoryTabs}
    </div>
  );
}

// = 地図表示:
function StadiumMap(StadiumMapProp: {
  stadiumMapData: StadiumMapData, typeNo: number, selectedCategoryId: string, selectedEventId: string, setSelectedEventId: any
  , setSelectedAreaData: any, floor: number, setFloor: any, mapRefresh: boolean, setMapRefresh: any, setSelectedCategoryId: any,
  needScroll: boolean, setNeedScroll: any
}) {
  const peripheryMapInfos = StadiumMapProp.stadiumMapData.peripheryMapInfos;
  const insideMapInfos = StadiumMapProp.stadiumMapData.insideMapInfos;
  const typeNo = StadiumMapProp.typeNo;
  const categoryId = StadiumMapProp.selectedCategoryId;
  const selectedEventId = StadiumMapProp.selectedEventId;
  const setSelectedEventId = StadiumMapProp.setSelectedEventId;

  const mapTransformRef = useRef<ReactZoomPanPinchContentRef | null>(null);

  useEffect(() => {
    if (!StadiumMapProp.needScroll) return;
    
    var infoAreaBody = document.getElementById('facility-info');//詳細領域
    var infoListArea = document.getElementById('scroll-body');//スクロールエリア
    var cells = document.getElementsByClassName(selectedEventId);
    if (infoAreaBody && infoListArea && cells && cells.length > 0) {
      var bodyTop = (infoListArea!.getBoundingClientRect().top);//スクロールする部分の上端
      var top = (cells[0].getBoundingClientRect().top);//詳細セルの上端
      infoAreaBody?.scrollTo(0, top - bodyTop);//本体の上端-対象セルの上端で絶対位置を計算
    }
    StadiumMapProp.setNeedScroll(false);
  });

  // 階層操作パネルの表示切替 
  changeFloorSelectorVisiblity(typeNo);
  // フロアパネルの生成
  var floorSelector = makeFloorSelector(insideMapInfos, StadiumMapProp.floor, StadiumMapProp.setFloor,
    setSelectedEventId, StadiumMapProp.setSelectedCategoryId, StadiumMapProp.setMapRefresh);

  var mapDatas = (typeNo === 0) ? peripheryMapInfos : insideMapInfos;
  // データが無いのであれば何もせずに終了
  // 　=> データなしの表示が必要であればここに入れる
  if (mapDatas.length < 1) return <div id='map-scrole' className='body'></div>;
  var mapLayout = <div id='map-scrole' className='map'></div>;

  var initScale = 1.0; //ピンチイン領域の初期スケール
  switch (typeNo) {
    case 0: //周辺マップ
      mapLayout = makeStadiumMap(mapDatas, categoryId, 0, selectedEventId, setSelectedEventId,
        StadiumMapProp.setSelectedAreaData, StadiumMapProp.setNeedScroll);//周辺情報は1Fしかない想定
      // 
      var maxSize = mapDatas[0].maxCoordinate.x;//横幅
      initScale = Math.ceil((contentWidth / maxSize) * 100) / 100;
      break;
    case 1: //屋内マップ
      mapLayout = makeStadiumMap(mapDatas, categoryId, StadiumMapProp.floor, selectedEventId,
        setSelectedEventId, StadiumMapProp.setSelectedAreaData, StadiumMapProp.setNeedScroll);
      var maxSize = mapDatas[StadiumMapProp.floor].maxCoordinate.x;//横幅
      initScale = Math.ceil((contentWidth / maxSize) * 100) / 100;
      break;
    default: //異常値なら何もない表示
      break;
  }

  const MapStateRefresher = (utils: ReactZoomPanPinchHandlers) => {
    if (StadiumMapProp.mapRefresh) {
      StadiumMapProp.setMapRefresh(false);
      // utils.resetTransform();
      selectedEventId == '' ? utils.resetTransform() : utils.zoomToElement(selectedEventId, initScale * 2);
    }
    // 自動発火のみが必要なので空を返す
    return <></>;
  }

  return (
    <div id='map-scrole' className='body'>
      <TransformWrapper minScale={initScale} initialScale={initScale} ref={mapTransformRef}
        centerZoomedOut={true} disablePadding={true} alignmentAnimation={{ disabled: true, sizeX: 0, sizeY: 0 }} >
        {(utils) => (
          <React.Fragment>
            <MapStateRefresher {...utils} />
            <TransformComponent>
              {mapLayout}
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
      {floorSelector}
    </div>
  );
}

function makeStadiumMap(datas: Array<MapData>, filterId: string, floorNo: number, selectedEventId: string,
  setSelectedEventId: any, setSelectedAreaData: any, setNeedScroll: any) {
  var mapData = datas[floorNo]; //フロアの情報
  // 
  var mapImagePath = mapData.mapImagePath; // 地図画像のパス
  var maxCoordinate = mapData.maxCoordinate; // 座標の最大値
  var makers: Array<JSX.Element> = []; // 配置するマーカー

  mapData.areaInfos.forEach(areaInof => { // 区画情報
    areaInof.facilitys.forEach(facility => { // 施設情報

      const categoryId = facility.markerCategoryId; // カテゴリID
      //カテゴリIDが無い = マーカー情報が無い(座標もない)　なので配置不能として終了
      if (categoryId.length === 0) return;

      //全件ではなく、IDが一致しない場合は配置せずに終了
      if (filterId != '' && categoryId != filterId) return;

      // マーカーの位置(比率)計算
      var xPosition = facility.location.x / maxCoordinate.x * 100 + '%';
      var yPosition = facility.location.y / maxCoordinate.y * 100 + '%';

      // 位置のスタイル設定(指定位置が中心になるようにアイコンのサイズの半分だけ引く)
      var style: React.CSSProperties = {}

      // 
      if (facility.iconImagePath?.length > 0) {//画像情報あり
        style.top = `calc(${yPosition} - ${markerHalf}px)`;
        style.left = `calc(${xPosition} - ${markerHalf}px)`;
        if (selectedEventId === facility.eventId) {
          style.transform = `translateZ(0)`;
          style.filter = `drop-shadow(-4px 0px 2px ${makeColor(facility.markerColor)}) \
          drop-shadow(4px 0px 2px ${makeColor(facility.markerColor)}) \ 
          drop-shadow(0px -4px 2px ${makeColor(facility.markerColor)}) \
          drop-shadow(0px 4px 2px ${makeColor(facility.markerColor)})`;
        }
        var img = <div id={facility.eventId} className='map-marker' onClick={
          () => markerOnClick(facility.eventId, setSelectedEventId, setNeedScroll)
        } style={style}><img className='map-marker' src={facility.iconImagePath} /> </div>
        makers.push(img);
      }
      else {//画像情報無し
        var calcpoint = (selectedEventId === facility.eventId) ? markerHalf + markerBorder : markerHalf;
        style.top = `calc(${yPosition} - ${calcpoint}px)`;
        style.left = `calc(${xPosition} - ${calcpoint}px)`;
        style.borderRadius = '50%';
        if (selectedEventId === facility.eventId) {
          style.backgroundColor = makeColor(facility.markerColor, true);
          style.border = `${mapMarkerSetting.borderWidth}px ${makeColor(facility.markerColor)} solid`;
          style.transform = `translateZ(0)`;
          style.filter = `drop-shadow(0px 0px ${mapMarkerSetting.borderWidth}px ${makeColor(facility.markerColor)})`;
        }
        else {
          style.backgroundColor = makeColor(facility.markerColor);
        }
        makers.push(<div id={facility.eventId} className='map-marker' onClick={
          () => markerOnClick(facility.eventId, setSelectedEventId, setNeedScroll)
        } style={style} />);
      }
    });
  });
  return (
    <div id='map-scrole' className='map'>
      {makers}
      <img alt='StadiumMap' id='map-scrole' className='image' src={mapImagePath} />
    </div>
  );
}

// マーカーを選択した際の処理
function markerOnClick(selectedID: string, setSelectedEventId: any, setNeedScroll: any) {
  setSelectedEventId(selectedID);
  setNeedScroll(true);
}

// 階層選択パネル
function makeFloorSelector(mapDatas: Array<MapData>, floor: number, setFloor: any, setSelectedEventId: any, setSelectedCategoryId: any, setMapRefresh: any) {
  function setFloorNo(floor: number) {
    setSelectedEventId('');// 選択状態のリセット
    setFloor(floor);       // 階層設定
    setSelectedCategoryId('')// カテゴリリセット
    setMapRefresh(true)
  }
  //フロア名の取り出し
  var floorNames = mapDatas.map(mapData => mapData.name);
  var panels = floorNames.map((name, idx) => {
    var className = 'floor-button';
    return <input type='button' id='floor-selector' className={className} onClick={() => setFloorNo(idx)} value={name} />
  });
  var floorButtons = document.getElementsByClassName('floor-button');
  if (floorButtons) {
    var buttonArray = Array.from(floorButtons);
    buttonArray.forEach((btn, idx) => {
      if (idx != floor) { btn.removeAttribute("disabled"); }
      else { btn.setAttribute("disabled", 'true'); }
    });
  }
  return (
    <div id='floor-selector' className='panel'>
      {panels}
    </div>
  );
}
function changeFloorSelectorVisiblity(typeNo: number) {
  var panel = document.getElementById('floor-selector');
  if (panel) {
    switch (typeNo) {
      case 0: panel.style.visibility = 'hidden'; break;
      case 1: panel.style.visibility = 'visible'; break;
      default: break;
    }
  }
}

//= #oooooo 形式の色をrgb(oo,oo,oo)形式に変更 + 明度調整。
function makeColor(base: string, bright: boolean = false) {
  var brightnes = bright ? 150 : 0; //明度を上げるために増量させる値
  // 
  var r = parseInt(base.slice(1, 3), 16) + brightnes;
  var g = parseInt(base.slice(3, 5), 16) + brightnes;
  var b = parseInt(base.slice(5, 7), 16) + brightnes;
  // 丸め込み
  r = (r > 255) ? 255 : r;
  g = (g > 255) ? 255 : g;
  b = (b > 255) ? 255 : b;
  // 
  return `rgb(${r},${g},${b})`;
}

// 選択されている施設の確認 
//  [mapDatas:地図情報全体, eventId:選択中の施設のeventId, selectId:選択したカテゴリID ]
function findSelectedItem(mapDatas: Array<MapData>, eventId: string, selectId: string) {
  var facility: FacilityData | null = null;
  mapDatas.forEach(mapData => {
    //もう見つかっている場合は止める (typescriptではforEach内でのreturnはbreak扱いになるため)
    if (facility != null) return false;
    mapData.areaInfos.forEach(area => {
      var item = area.facilitys.find(data => { return data.markerCategoryId === selectId && data.eventId === eventId });
      if (item) facility = item;
    });
  });
  return facility != null;
}

// 指定カテゴリのデータが0件かどうか
function isCategoryDataEmpty(data: StadiumMapData, targetMap: number, floor: number, categoryId: string): boolean {
  var mapData: MapData;
  switch (targetMap) {
    case 0: mapData = data.peripheryMapInfos[0]; break;
    case 1: mapData = data.insideMapInfos[floor]; break;
    default: return true;
  }
  var facilities = mapData?.areaInfos.reduce((acc: Array<FacilityData>, val: AreaData): Array<FacilityData> => {
    acc.push(...val.facilitys)
    return acc
  }, new Array<FacilityData>()).filter((value) => {
    return value.markerCategoryId === categoryId
  })

  return facilities?.length == 0 ?? true
}
