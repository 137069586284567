/* =| 固定値 |= */

// マップの種類
const mapTypeText = {
  periphery: 'スタジアム周辺',
  inside: 'スタジアム内'
} as const

// --

// マップの種類名 [0:周辺, 1:内部, -1:不明]
export const getMapTypeName = (typeNo: number) => {
  switch (typeNo) {
    case 0: return mapTypeText.periphery;
    case 1: return mapTypeText.inside;
    default: return ''; //不正値なら空文字を返す
  }
}
// マーカーのデータ
export const mapMarkerSetting = {
  size: 30,
  borderWidth: 5,
}

// 文字列の最大値
export const textSettings = {
  maxCategoryName: 32,
  maxInformationText: 1024
}

// index.html内で指定している画面幅の値
export const contentWidth = 800;