/* =|  |= */
import { requestGetStadiumMap } from '../../data/repository/requestStadiumMap';
import { FootBallStadiumMapModel } from '../../data/entity/modelStadiumMap';

import { mapTypeKey } from '../value/domainValues';
import { StadiumMapData, MapData, AreaData, FacilityData, CategoryData } from '../models/stadiumMapData';
// ==
// スタジアムマップデータの取得
export async function getStadiumMapData() {
  return await requestGetStadiumMap().then((model) => {
    switch (model.code) {
      case 200: return makeStadiumMapData(model);
      default: return null;
    }
  }).catch(error => {
    console.error("アクセスエラー: %s", error)
    return null;
  });
}
function makeStadiumMapData(model: FootBallStadiumMapModel) {
  var data: StadiumMapData = { insideMapInfos: [], peripheryMapInfos: [], categoryInfos: [] };

  // カテゴリの展開
  data.categoryInfos = model.mapData.stadiummap.categories.map(categoryModel => {
    var category: CategoryData = {
      categoryId: categoryModel.category_id,
      name: categoryModel.name,
      markerColor: categoryModel.color,
      markerURL: categoryModel.image_path
    };
    return category;
  });

  // マップ情報の展開
  const mapDataModel = model.mapData.stadiummap.stadiummaps;
  mapDataModel.forEach(mapModel => {
    mapModel.stadiumsubmaps.forEach(submaps => {
      var floorName = submaps.name; //フロア階層
      var mapData: MapData = {
        name: submaps.name,
        mapImagePath: submaps.image_path,
        maxCoordinate: { x: submaps.x_max, y: submaps.y_max },
        areaInfos: []
      };
      // マーカー
      var markers = submaps.markers;
      // エリア: フロアの区画情報
      submaps.areas.forEach(area => {
        var areadata: AreaData = { title: area.title, facilitys: [] };
        var facilitys = area.events;//施設情報(イベント情報)
        facilitys.forEach(facility => {
          // 対応するマーカーを抽出
          var maker = markers?.find(marke => marke.event_id === facility.event_id);
          var markerId = '';
          var location = { x: 0, y: 0 };
          var markerCategoryId = '';
          var markerColor = "#FFFFFF";
          var iconImagePath = '';
          if (maker) {
            markerId = maker.marker_id;
            location = { x: maker.location_x, y: maker.location_y };
            markerCategoryId = maker.category_id;
            var category = data.categoryInfos.find(category => category.categoryId === maker?.category_id);
            if (category) {
              markerColor = category.markerColor;
              iconImagePath = category.markerURL;
            }
          }
          // 
          areadata.facilitys.push({
            eventId: facility.event_id,
            type: facility.type,
            sortKey: facility.sort_key,
            imagePath: facility.image_path,
            description: facility.text,
            detail: facility.description,
            markerId: markerId,
            location: location,
            markerColor: markerColor,
            iconImagePath: iconImagePath,
            markerCategoryId: markerCategoryId
          });
        });
        // 
        mapData.areaInfos.push(areadata);
      });

      // マップデータの詰め込み
      switch (mapTypeKey(mapModel.name)) {
        case 0: data.peripheryMapInfos.push(mapData); break; // 周辺マップ
        case 1: data.insideMapInfos.push(mapData); break; // スタジアム内マップ
        default: break; // 不明
      }
    });
  });


  return data;
}

