import React from 'react';
import {StudiumMap} from './ui/view/studiumMap'
import './App.css';

// ==
function App() {
  return <StudiumMap/>;
}

export default App;
