/* =| スタジアムマップ機能で使用するデータの授受 |= */

import { apiRequest } from '../datasource/sangaApi';
import { FootBallStadiumMapModel, StadiumMapDataModel } from '../entity/modelStadiumMap';

import { StadiumMapModel, SubStadiumMapModel, AreaModel, EventModel, MarkerModel, CategoryModel } from '../entity/modelStadiumMap';


// ==
// スタジアムマップ情報のJSONファイル取得リクエスト
export async function requestGetStadiumMap() {
  return await apiRequest().then(async (res) => {
    var statusCode = res.status;
    return await res.json().then((body: JSON) => {
      var model = statusCode != 200 ? { stadiummap: { stadiummaps: [], categories: [] } } : stadiumJson(body);
      const responseModel: FootBallStadiumMapModel = { code: statusCode, mapData: model }
      return responseModel;
    });
  });
}

// スタジアムマップJSONのパース
function stadiumJson(json: JSON) {
  const parsed = JSON.parse(JSON.stringify(json));

  //そのまま詰め込んでも平気そうなので、そのまま入れてしまう。
  var model: StadiumMapDataModel = {
    stadiummap: {
      stadiummaps: parsed.stadiummap.stadiummaps,
      categories: parsed.stadiummap.categories
    }
  };

  return checkRequiredItem(model);
}
// 必須項目チェック
function checkRequiredItem(model: StadiumMapDataModel) {
  // マップデータ 
  model.stadiummap.stadiummaps.forEach((stadiummap) => {
    if (!stadiummap.name || stadiummap.name === '') throw Error();
    stadiummap.stadiumsubmaps.forEach((stadiumsubmap) => {
      if (!stadiumsubmap.image_path || stadiumsubmap.image_path === '' || !stadiumsubmap.x_max || !stadiumsubmap.y_max) throw Error();
      stadiumsubmap.areas.forEach((area) => {
        area.events.forEach((event) => {
          if (!event.event_id || event.event_id === '') throw Error();
          if (!event.type) event.type = '';
          if (!event.sort_key) event.sort_key = 0;
        });
      });
    });
  });
  // カテゴリ
  model.stadiummap.categories.forEach((category) => {
    if (!category.category_id || category.category_id === '') throw Error();
    if (!category.name) category.name = '';
    if (!category.color) category.color = '#920784';
  })
  // 
  return model;
}

// マップの展開
function stadiummapsJsonParser(json: JSON) {
  var stadiummapsArray: Array<StadiumMapModel> = [];

  const parsedJsonArray: Array<JSON> = JSON.parse(JSON.stringify(json));
  parsedJsonArray.map(stadiummaps => {
    const parsedStadiummaps = JSON.parse(JSON.stringify(stadiummaps));
    stadiummapsArray.push({
      name: parsedStadiummaps.name,
      stadiumsubmaps: statiumsubmapJsonParser(parsedStadiummaps.stadiumsubmaps)
    });
  });
  return stadiummapsArray;
}

// サブマップの展開
function statiumsubmapJsonParser(json: JSON) {

  var statiumsubmapArray: Array<SubStadiumMapModel> = [];
  const parsedJsonArray: Array<JSON> = JSON.parse(JSON.stringify(json));

  parsedJsonArray.map(statiumsubmap => {
    var parsedStatiumsubmap = JSON.parse(JSON.stringify(statiumsubmap));
    // マーカーは必須ではないのでundefindeチェック
    var markers: MarkerModel[] = parsedStatiumsubmap.markers === undefined ? [] : markersJsonParser(parsedStatiumsubmap.markers);

    statiumsubmapArray.push({
      name: parsedStatiumsubmap.name,
      image_path: parsedStatiumsubmap.image_path,
      x_max: parsedStatiumsubmap.x_max,
      y_max: parsedStatiumsubmap.y_max,
      areas: areaJsonParser(parsedStatiumsubmap.areas),
      markers: markers
    });
  });
  return statiumsubmapArray;
}
// エリアの展開
function areaJsonParser(json: JSON) {
  var areaArray: Array<AreaModel> = [];
  const parsedJsonArray: Array<JSON> = JSON.parse(JSON.stringify(json));
  parsedJsonArray.map(area => {
    var parsedArea = JSON.parse(JSON.stringify(area));
    var areaModel: AreaModel = {
      title: parsedArea.title,
      events: eventJsonParser(parsedArea.events)
    };
    //
    areaArray.push(areaModel);
  });
  return areaArray;
}
// イベントの展開
function eventJsonParser(json: JSON) {
  var eventArray: Array<EventModel> = [];
  const parsedJsonArray: Array<JSON> = JSON.parse(JSON.stringify(json));
  parsedJsonArray.map(maker => {
    const parsedEvent = JSON.parse(JSON.stringify(maker));
    eventArray.push({
      event_id: parsedEvent.event_id,
      type: parsedEvent.type,
      sort_key: parsedEvent.sort_key,
      image_path: parsedEvent.image_path,
      text: parsedEvent.text,
      description: parsedEvent.description
    });
  });
  return eventArray;
}
// マーカーの展開
function markersJsonParser(json: JSON) {
  var markerArray: Array<MarkerModel> = [];
  const parsedJsonArray: Array<JSON> = JSON.parse(JSON.stringify(json));
  parsedJsonArray.map(maker => {
    const parsedMaker = JSON.parse(JSON.stringify(maker));
    markerArray.push({
      marker_id: parsedMaker.marker_id,
      event_id: parsedMaker.event_id,
      category_id: parsedMaker.category_id,
      location_x: parsedMaker.location_x,
      location_y: parsedMaker.location_y
    });
  });
  return markerArray;
}
// カテゴリの展開
function categoryJsonParser(json: JSON) {
  var categoryArray: Array<CategoryModel> = [];

  const parsedJsonArray: Array<JSON> = JSON.parse(JSON.stringify(json));
  parsedJsonArray.map(category => {
    var parsedCategory = JSON.parse(JSON.stringify(category));
    categoryArray.push({
      category_id: parsedCategory.category_id,
      name: parsedCategory.name,
      color: parsedCategory.color,
      image_path: parsedCategory.image_path
    });
  });
  return categoryArray;
}