/* =| Domain層で使用する固定値 |= */

// マップの種類を示すテキスト (サブマップ.name)
const mapTypeText = {
  periphery: 'スタジアム周辺',
  inside: 'スタジアム内'
} as const

// マップの種類 [0:周辺, 1:内部, -1:不明]
export const mapTypeKey = (key: string) => {
  switch (key) {
    case mapTypeText.periphery: return 0;
    case mapTypeText.inside: return 1;
    default: return -1;
  }
}