/*=| 共有データ等を詰め込む場所 |=*/

const keySelectedEvent = "SelectedEvent";   // イベントID
const keyMapType = "MapType";         // 地図タイプ(周辺/内部)
const keyFloorNo = "FloorNo";         // 地図の階層
const keySelectedCategory = "SelectedCategory";// フィルタリングをするカテゴリID

// ==
const setStrageSelectedEvent = (value: string) => sessionStorage.setItem(keySelectedEvent, value);
const setStrageMapType = (value: number) => sessionStorage.setItem(keyMapType, `${value}`);
const setStrageFloorNo = (value: number) => sessionStorage.setItem(keyFloorNo, `${value}`);
const setStrageSelectedCategory = (value: string) => sessionStorage.setItem(keySelectedCategory, value);

//
const getStrageSelectedEvent = () => sessionStorage.getItem(keySelectedEvent);
const getStrageMapType = () => sessionStorage.getItem(keyMapType);
const getStrageFloorNo = () => sessionStorage.getItem(keyFloorNo);
const getStrageSelectedCategory = () => sessionStorage.getItem(keySelectedCategory);


// ==
export const fetchStrageSelectedEvent = () => checkNullString(getStrageSelectedEvent());
export const fetchStrageMapType = () => convertNumber(getStrageMapType());
export const fetchStrageFloorNo = () => convertNumber(getStrageFloorNo());
export const fetchStrageSelectedCategory = () => checkNullString(getStrageSelectedCategory());

// 
export const storeStrageSelectedEvent = (value: string) => setStrageSelectedEvent(value);
export const storeStrageMapType = (value: number) => setStrageMapType(value);
export const storeStrageFloorNo = (value: number) => setStrageFloorNo(value);
export const storeStrageSelectedCategory = (value: string) => setStrageSelectedCategory(value);

//Nullチェック 
const checkNullString = (target: string | null) => (target) ? target : '';
// 数値へ変換
const convertNumber = (target: string | null) => Number(target);

// 
export function storeSelectData(eventId: string, mapType: number, floorNo: number, categoryId: string) {
  storeStrageSelectedEvent(eventId);
  storeStrageMapType(mapType);
  storeStrageFloorNo(floorNo);
  storeStrageSelectedCategory(categoryId);
}
