/* =| Data層で使用する固定値 |= */

//= 接続先環境 [0:Dev, 1:Stg ,2: Prod] =//
const environment: number = 2;

//= 接続先の情報 =//
const Endpoints = {
  DEV: "https://dev-sanga-calender-map-app-local.s3.ap-northeast-1.amazonaws.com/map/football_stadium_map.json",
  STG: "https://app.footballteams.stg-sanga-kddi-fun-app.com/stadium-map",
  PRD: "https://app.footballteams.sanga-kddi-fun-app.com/stadium-map"
} as const
type Endpoints = typeof Endpoints[keyof typeof Endpoints];

//- 接続先取得
export function fetchURI() {
  switch (environment) {
    case 0: return Endpoints.DEV; // 開発環境
    case 1: return Endpoints.STG; // 検証環境
    case 2: return Endpoints.PRD; // 本番環境
    default: return Endpoints.DEV; // 変な値であれば検証環境を投げる
  }
}