/*=| 施設情報を表示するエリア |=*/
import Htmlparse from 'html-react-parser';

import { AreaData, FacilityData } from '../../domain/models/stadiumMapData';
import './styles/informationView.css';
import { textSettings } from '../value/values';

// 施設情報詳細部分(Array<AreaData>、選択中のeventIdをパラメータにする)
export function InformationView(InformationViewProp: {
  areaData: Array<AreaData>, selectedEventId: string, setSelectedEventId: any, setMapRefresh: any
  setNeedScroll: any
}) {

  const handleToggle = (eventId: string) => {
    if (InformationViewProp.selectedEventId === eventId) {
      InformationViewProp.setSelectedEventId('');
    } else {
      InformationViewProp.setSelectedEventId(eventId);
      InformationViewProp.setMapRefresh(true);
      InformationViewProp.setNeedScroll(true);
    }
  };

  return (
    <div id="information" className="body">
      <div id='facility-info' className='body'>
        <div id="scroll-body">
          {InformationViewProp.areaData.map((areaData: AreaData) => (
            <div>
              <AreaTitle key={areaData.title} {...areaData} />
              {areaData.facilitys.map((facilitys: FacilityData) => (
                <div>
                  <Cell facility={facilitys} isOpen={InformationViewProp.selectedEventId === facilitys.eventId}
                    onToggle={() => handleToggle(facilitys.eventId)} setSelectedEventId={InformationViewProp.setSelectedEventId} />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

// エリアタイトル
const AreaTitle = (title: AreaData) => <div id='facility-info' className='title'>{title.title}</div>;

type CellProps = {
  facility: FacilityData
  isOpen: boolean;
  onToggle: () => void;
  setSelectedEventId: any;
}

// 施設情報セル
const Cell: React.FC<CellProps> = ({ facility, isOpen, onToggle, setSelectedEventId }) => {
  var style: React.CSSProperties = { marginBottom: '10px', boxSizing: 'border-box' };
  var collapseMark = '＋'
  if (isOpen) {
    style.outline = '2px #920784AA solid';
    style.filter = `drop-shadow(0px 0px 5px #920784AA)`
    collapseMark = '－'
  }

  return (
    <div style={style}>
      <div id='facility-info' className={`cell ${facility.eventId}`} onClick={() => onToggle()}>
        <div id='cell-item' className='icon-area'>
          <img id='cell-item' className='icon' src={facility.imagePath} alt="image" />
        </div>
        <div id='cell-item' className='title-area'>
          <div id='cell-item' className='info'>{facility.description}</div>
          <div id='cell-item' className='collapse-mark' >{collapseMark}</div>
        </div>
      </div>
      <Detail facility={facility} isOpen={isOpen} />
    </div>
  )
}

// 詳細テキスト表示
const Detail = (DetailProp: { facility: FacilityData, isOpen: boolean }) => {
  // 選択されたら展開する
  if (!DetailProp.isOpen) { return null }
  const detail = DetailProp.facility.detail.length <= textSettings.maxInformationText
    ? Htmlparse(DetailProp.facility.detail)
    : Htmlparse(DetailProp.facility.detail.slice(0, textSettings.maxInformationText)) + '…'

  return <div id='cell-item' className='detail'>
    <div id='cell-item' className='detail-text'>{detail}</div>
  </div>
}